<template>
  <div title="List Users">
    <b-container fluid="sm" class="mb-5">
      <b-row class="text-right">
        <b-col>
          <b-button @click="createUser" variant="success">
            <plus-icon size="1.2x" class="custom-class"></plus-icon>ADD
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="employeesData" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :line-numbers="true" :pagination-options="{
  enabled: true,
  perPage: pageLength
}">
      <template slot="table-row" slot-scope="props">

        <!-- Column: Name -->
        <span v-if="props.column.field === 'firstname'" class="text-nowrap">
          <b-avatar :src="props.row.image" class="mx-1" />
          <span class="text-nowrap">{{ props.row.firstname }}</span>
        </span>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item :to="{ name: 'user-view', params: { id: props.row.id } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'user-edit', params: { id: props.row.id } }">
                <feather-icon icon="RepeatIcon" class="mr-50" />
                <span>Reset Password</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span @click="deleteEmployee(props.row.id)">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['5', '10', '25']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BContainer, BRow, BCol
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { PlusIcon } from 'vue-feather-icons'
//Database
import { db } from "@/main";
import firebase from "firebase";
import auth from "firebase/auth";
import { getAuth } from 'firebase/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'


export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BContainer,
    BRow,
    BCol,
    PlusIcon,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      employeesData: [],
      columns: [
        {
          label: 'First Name',
          field: 'firstname',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  methods: {
    createUser() {
      this.$router.push({ name: 'user-create' })
    },

    readEmployees() {
      store.dispatch('app/commitActivateLoader');

      this.employeesData = [];
      var i = 1;
      db.collection("users")
        .orderBy("created", 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.employeesData.push({
              index: i,
              firstname: doc.data().firstname,
              lastname: doc.data().lastname,
              email: doc.data().email,
              image: doc.data().image,
              nationality: doc.data().nationality,
              country: doc.data().country,
              phone_no: doc.data().phone_no,
              id: doc.id,
              website: doc.data().website,
              created: doc.data().created,
              modified: doc.data().modified,
            });
            // console.log(doc.id, " => ", doc.data());
            i++;
            store.dispatch('app/commitDeactivateLoader');

          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          store.dispatch('app/commitDeactivateLoader');

        });
    },
    deleteEmployee(id) {
      if (confirm("Do you really want to delete?")) {
        // alert('dddd');
        const url = this.$VUE_APP_API_ENDPOINT + "delete-auth-users";

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          data: {id:id},
          // data: JSON.stringify(id),
          url: url
        };
        axios(options).then(
          (response) => {
            // console.log("user delete =>", response)
            this.readEmployees();
            this.showNotification('User deleted successfully', 'UserIcon', 'danger');
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            store.dispatch('app/commitDeactivateLoader');
            this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
          });
        // db.collection("users")
        //   .doc(id)
        //   .delete()
        //   .then(() => {
        //     console.log("Document successfully deleted!");
        //     this.readEmployees();
        //     this.showNotification('User deleted successfully', 'UserIcon', 'danger');
        //     //this.$router.push('/users/index');
        //   })
        //   .catch((error) => {
        //     console.error("Error removing document: ", error);
        //   });
      }
    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => {
        this.rows = res.data
        console.log(this.rows);
      })
  },
  mounted() {
    // var uid = "CSUlyFrTDSWJugZrmis4Bi6S8h52"
    // // getAuth
    // firebase.auth()
    //   .deleteUser(uid)
    //   .then(() => {
    //     console.log('Successfully deleted user');
    //   })
    //   .catch((error) => {
    //     console.log('Error deleting user:', error);
    //   });
    // console.log("current_user =>", firebase.auth())
    // firebase.auth().onAuthStateChanged(user => {
    //   console.log("user", user);
    // });
    // alert();
    this.readEmployees();
    console.log(this.employeesData);
  }
}
</script>
